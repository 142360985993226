<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :title="$t('welcome') + ', ' + current_user.name"
  >
    <template v-slot:header_options>
      <a-btn-pdf
        v-if="has_permission('export_pdf')"
        :config="pdf_config"
        class="mr-4"
      ></a-btn-pdf>
      <a-rms-import-dates ref="rms_import_dates"></a-rms-import-dates>
    </template>

    <dashboard-rms-group-app
      v-if="current_hotel && isGroup"
      ref="dashboard_rms_hotelsdot_group"
    ></dashboard-rms-group-app>
    <dashboard-rms-app
      v-if="current_hotel && !isGroup"
      ref="dashboard_rms_app"
    ></dashboard-rms-app>
  </a-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import dashboardRmsApp from "@/components/app/dashboardRmsApp";
import dashboardRmsGroupApp from "@/components/app/dashboardRmsGroupApp";
import aRmsImportDates from "@/components/rms/aRmsImportDates.vue";
import aBtnPdf from "@/components/aBtnPdf";

export default {
  components: {
    aPage,
    dashboardRmsApp,
    dashboardRmsGroupApp,
    aRmsImportDates,
    aBtnPdf
  },
  data: () => ({
    image_source: "/img/header.jpg" //Mandatory path
  }),
  computed: {
    image_height() {
      return this.isGroup ? 130 : 505;
    },
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    pdf_config() {
      return {
        orientation: "portrait",
        filename: this.isGroup
          ? this.$t("dashboard_group")
          : this.$t("dashboard"),
        elements: [
          {
            id: this.isGroup ? "dashboard-rms-group-app" : "dashboard_rms_app",
            is_table: false
          }
        ],
        hotel_name: this.current_hotel.name,
        section_name: this.isGroup
          ? this.$t("dashboard_group")
          : this.$t("dashboard")
      };
    },
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      has_permission: "has_permission"
    })
  },
  created() {}
};
</script>
