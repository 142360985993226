var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a-card',{staticClass:"hotel-list-item",class:hover ? 'elevation-4' : 'elevation-1',style:('height:' + _vm.height + 'px;'),attrs:{"title":_vm.hotel.name,"header_color":"grey lighten-4"},scopedSlots:_vm._u([{key:"pretitle",fn:function(){return [_c('v-avatar',{staticClass:"mr-1",attrs:{"color":_vm.hotel.settings.color,"size":"16"}},[_c('span',{staticClass:"white--text font-weight-bold",staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.hotel.settings.stars)+" ")])])]},proxy:true},(_vm.currency_converted)?{key:"subtitle",fn:function(){return [_c('a-currency-conversion',{attrs:{"hotel":_vm.hotel}})]},proxy:true}:null,{key:"options",fn:function(){return [(_vm.hotel_data)?_c('v-row',{staticStyle:{"max-width":"850px"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex justify-center align-end"},[_c('a-period',{staticClass:"mr-2",attrs:{"period":"ytd","dense":""}}),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.ytd.value,"vs_values":[_vm.hotel_data.ytd.vs_value],"vs_periods":[_vm.dashboard_group_vs_data],"x_small":""}})],1),_c('v-progress-linear',{staticClass:"mt-3 px-6",attrs:{"value":_vm.getPercent(_vm.hotel_data.ytd.value, _vm.hotel_data.ytd.vs_value),"rounded":"","height":"4","width":"100","background-color":"grey lighten-3","color":_vm.hotel_data.ytd.value > _vm.hotel_data.ytd.vs_value
                ? 'success'
                : 'blue'}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex justify-center align-end"},[_c('a-period',{staticClass:"mr-2",attrs:{"period":"full_year","dense":""}}),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.full_year.value,"vs_values":[_vm.hotel_data.full_year.vs_value],"vs_periods":[_vm.dashboard_group_vs_data],"background-color":"grey lighten-3","x_small":""}})],1),_c('v-progress-linear',{staticClass:"mt-3 px-6",attrs:{"value":_vm.getPercent(
                _vm.hotel_data.full_year.value,
                _vm.hotel_data.full_year.vs_value
              ),"rounded":"","height":"4","width":"100","background-color":"grey lighten-3","color":_vm.hotel_data.full_year.value > _vm.hotel_data.full_year.vs_value
                ? 'success'
                : 'blue'}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex justify-center align-end"},[_c('a-period',{staticClass:"mr-2",attrs:{"period":"mtd","dense":""}}),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.mtd.value,"vs_values":[_vm.hotel_data.mtd.vs_value],"vs_periods":[_vm.dashboard_group_vs_data],"x_small":""}})],1),_c('v-progress-linear',{staticClass:"mt-3 px-6",attrs:{"value":_vm.getPercent(_vm.hotel_data.mtd.value, _vm.hotel_data.mtd.vs_value),"rounded":"","height":"4","width":"100","background-color":"grey lighten-3","color":_vm.hotel_data.mtd.value > _vm.hotel_data.mtd.vs_value
                ? 'success'
                : 'blue'}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex justify-center align-end"},[_c('a-period',{staticClass:"mr-2",attrs:{"period":"full_month","dense":""}}),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.full_month.value,"vs_values":[_vm.hotel_data.full_month.vs_value],"vs_periods":[_vm.dashboard_group_vs_data],"x_small":""}})],1),_c('v-progress-linear',{staticClass:"mt-3 px-6",attrs:{"value":_vm.getPercent(
                _vm.hotel_data.full_month.value,
                _vm.hotel_data.full_month.vs_value
              ),"rounded":"","height":"4","width":"100","background-color":"grey lighten-3","color":_vm.hotel_data.full_month.value > _vm.hotel_data.full_month.vs_value
                ? 'success'
                : 'blue'}})],1)],1):_vm._e()]},proxy:true}],null,true)},[(_vm.hotel_data)?_c('v-window',{model:{value:(_vm.hotel_list_items_slide),callback:function ($$v) {_vm.hotel_list_items_slide=$$v},expression:"hotel_list_items_slide"}},[_c('v-window-item',{attrs:{"value":0}},[(_vm.hotel_data)?_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{staticClass:"text-center columns-auto",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"1"}},[_c('a-period-compare',{attrs:{"periods":['ytd', _vm.dashboard_group_vs_data],"vertical":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("occupancy"))+" ")]),_c('a-kpi',{attrs:{"kpi":"occupancy","value":_vm.hotel_data.year.occupancy[0].otb,"vs_values":[
                    _vm.hotel_data.year.occupancy[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("adr"))+" ")]),_c('a-kpi',{attrs:{"kpi":"adr","value":_vm.hotel_data.year.adr[0].otb,"vs_values":[
                    _vm.hotel_data.year.adr[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("revpar"))+" ")]),_c('a-kpi',{attrs:{"kpi":"revpar","value":_vm.hotel_data.year.revpar[0].otb,"vs_values":[
                    _vm.hotel_data.year.revpar[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("room_revenue"))+" ")]),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.year.room_revenue[0].otb,"vs_values":[
                    _vm.hotel_data.year.room_revenue[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("intermediation"))+" ")]),_c('a-kpi',{attrs:{"kpi":"intermediation","value":_vm.hotel_data.year.intermediation[0].otb,"vs_values":[
                    _vm.hotel_data.year.intermediation[0][
                      'dashboard_group_vs_data'
                    ]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('a-dashboard-year-sales-roomrevenue-monthly-chart',{staticClass:"mb-n2 ",attrs:{"series":_vm.hotel_data.year_sales_monthly_graph.kpis.room_revenue,"labels":_vm.hotel_data.year_sales_monthly_graph.labels,"height":"70","id":'a-dashboard-year-sales-roomrevenue-monthly-chart-' + _vm.hotel.id}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"1"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{staticStyle:{"position":"absolute","top":"50%","transform":"translateY(-50%)","right":"0"},attrs:{"fab":"","color":"secondary","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.setCurrentHotel($event)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])],1):_vm._e()],1),_c('v-window-item',{attrs:{"value":1}},[(_vm.hotel_data)?_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{staticClass:"text-center columns-auto",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('a-period-compare',{attrs:{"periods":['mtd', _vm.dashboard_group_vs_data],"vertical":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("occupancy"))+" ")]),_c('a-kpi',{attrs:{"kpi":"occupancy","value":_vm.hotel_data.month.occupancy[0].otb,"vs_values":[
                    _vm.hotel_data.month.occupancy[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("adr"))+" ")]),_c('a-kpi',{attrs:{"kpi":"adr","value":_vm.hotel_data.month.adr[0].otb,"vs_values":[
                    _vm.hotel_data.month.adr[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("revpar"))+" ")]),_c('a-kpi',{attrs:{"kpi":"revpar","value":_vm.hotel_data.month.revpar[0].otb,"vs_values":[
                    _vm.hotel_data.month.revpar[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("room_revenue"))+" ")]),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.month.room_revenue[0].otb,"vs_values":[
                    _vm.hotel_data.month.room_revenue[0][
                      'dashboard_group_vs_data'
                    ]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("intermediation"))+" ")]),_c('a-kpi',{attrs:{"kpi":"intermediation","value":_vm.hotel_data.month.intermediation[0].otb,"vs_values":[
                    _vm.hotel_data.month.intermediation[0][
                      'dashboard_group_vs_data'
                    ]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('a-pickup-roomrevenue-revpar-chart',{staticClass:"mb-n2",attrs:{"height":"70","id":'a-pickup-roomrevenue-revpar-chart-current-month-' + _vm.hotel.id,"series":_vm.hotel_data.kpis_pickup_month_graphs.kpis,"labels":_vm.hotel_data.kpis_pickup_month_graphs.labels,"hide_x":""}})],1)],1):_vm._e()],1),_c('v-window-item',{attrs:{"value":2}},[(_vm.hotel_data)?_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{staticClass:"text-center columns-auto",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"mb-3"},[_c('a-period-compare',{attrs:{"periods":['mtd', _vm.dashboard_group_vs_data],"vertical":""}})],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("occupancy"))+" ")]),_c('a-kpi',{attrs:{"kpi":"occupancy","value":_vm.hotel_data.next_month.occupancy[0].otb,"vs_values":[
                    _vm.hotel_data.next_month.occupancy[0][
                      'dashboard_group_vs_data'
                    ]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("adr"))+" ")]),_c('a-kpi',{attrs:{"kpi":"adr","value":_vm.hotel_data.next_month.adr[0].otb,"vs_values":[
                    _vm.hotel_data.next_month.adr[0][_vm.dashboard_group_vs_data]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("revpar"))+" ")]),_c('a-kpi',{attrs:{"kpi":"revpar","value":_vm.hotel_data.next_month.revpar[0].otb,"vs_values":[
                    _vm.hotel_data.next_month.revpar[0]['dashboard_group_vs_data']
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("room_revenue"))+" ")]),_c('a-kpi',{attrs:{"kpi":"room_revenue","value":_vm.hotel_data.next_month.room_revenue[0].otb,"vs_values":[
                    _vm.hotel_data.next_month.room_revenue[0][
                      'dashboard_group_vs_data'
                    ]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a-label',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("intermediation"))+" ")]),_c('a-kpi',{attrs:{"kpi":"intermediation","value":_vm.hotel_data.next_month.intermediation[0].otb,"vs_values":[
                    _vm.hotel_data.next_month.intermediation[0][
                      'dashboard_group_vs_data'
                    ]
                  ],"vs_periods":[_vm.dashboard_group_vs_data],"small":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('a-pickup-roomrevenue-revpar-chart',{staticClass:"mb-n2",attrs:{"height":"70px","id":'a-pickup-roomrevenue-revpar-chart-next-month-' + _vm.hotel.id,"series":_vm.hotel_data.kpis_pickup_next_month_graphs.kpis,"labels":_vm.hotel_data.kpis_pickup_next_month_graphs.labels,"hide_x":""}})],1)],1):_vm._e()],1)],1):_c('v-window',[_c('v-window-item',[_c('v-row',_vm._l((6),function(index){return _c('v-col',{key:index,staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-skeleton-loader',{attrs:{"type":"chip"}})],1)}),1),_c('v-row',_vm._l((6),function(index){return _c('v-col',{key:index,staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)}),1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }