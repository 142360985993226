<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <v-row class="mb-4">
      <v-col class="text-center">
        <a-label class="mb-0">
          {{ $t("occupancy") }}
        </a-label>
        <div class="mb-3">
          <a-period-compare :periods="[year_period, 'stly']"></a-period-compare>
        </div>
        <a-kpi
          v-if="rms.dashboard_annual_kpis"
          kpi="occupancy"
          :value="rms.dashboard_annual_kpis['otb'].occupancy"
          :vs_values="[rms.dashboard_annual_kpis.stly.occupancy]"
          :vs_periods="['stly']"
          large
        ></a-kpi>
      </v-col>
      <v-col class="text-center">
        <a-label class="mb-0">
          {{ $t("rn") }}
        </a-label>
        <div class="mb-3">
          <a-period-compare :periods="[year_period, 'stly']"></a-period-compare>
        </div>
        <a-kpi
          v-if="rms.dashboard_annual_kpis"
          kpi="rn"
          :value="rms.dashboard_annual_kpis['otb'].rn"
          :vs_values="[rms.dashboard_annual_kpis.stly.rn]"
          :vs_periods="['stly']"
          large
        ></a-kpi>
      </v-col>
      <v-col class="text-center">
        <a-label class="mb-0">
          {{ $t("adr") }}
        </a-label>
        <div class="mb-3">
          <a-period-compare :periods="[year_period, 'stly']"></a-period-compare>
        </div>
        <a-kpi
          v-if="rms.dashboard_annual_kpis"
          kpi="adr"
          :value="rms.dashboard_annual_kpis['otb'].adr"
          :vs_values="[rms.dashboard_annual_kpis.stly.adr]"
          :vs_periods="['stly']"
          large
        ></a-kpi>
      </v-col>
      <v-col class="text-center">
        <a-label class="mb-0">
          {{ $t("revpar") }}
        </a-label>
        <div class="mb-3">
          <a-period-compare :periods="[year_period, 'stly']"></a-period-compare>
        </div>
        <a-kpi
          v-if="rms.dashboard_annual_kpis"
          kpi="revpar"
          :value="rms.dashboard_annual_kpis['otb'].revpar"
          :vs_values="[rms.dashboard_annual_kpis.stly.revpar]"
          :vs_periods="['stly']"
          large
        ></a-kpi>
      </v-col>
      <v-col class="text-center">
        <a-label class="mb-0">
          {{ $t("room_revenue") }}
        </a-label>
        <div class="mb-3">
          <a-period-compare :periods="[year_period, 'stly']"></a-period-compare>
        </div>
        <a-kpi
          v-if="rms.dashboard_annual_kpis"
          kpi="room_revenue"
          :value="rms.dashboard_annual_kpis['otb'].room_revenue"
          :vs_values="[rms.dashboard_annual_kpis.stly.room_revenue]"
          :vs_periods="['stly']"
          large
        ></a-kpi>
      </v-col>
    </v-row>
    <template v-slot:loading>
      <v-row class="mb-4">
        <v-col class="text-center">
          <a-label class="mb-0">
            {{ $t("occupancy") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare
              :periods="[year_period, 'stly']"
            ></a-period-compare>
          </div>
          <v-skeleton-loader class="mx-auto py-2" type="text" max-width="150px">
          </v-skeleton-loader>
        </v-col>
        <v-col class="text-center">
          <a-label class="mb-0">
            {{ $t("rn") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare
              :periods="[year_period, 'stly']"
            ></a-period-compare>
          </div>
          <v-skeleton-loader class="mx-auto py-2" type="text" max-width="150px">
          </v-skeleton-loader>
        </v-col>
        <v-col class="text-center">
          <a-label class="mb-0">
            {{ $t("adr") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare
              :periods="[year_period, 'stly']"
            ></a-period-compare>
          </div>
          <v-skeleton-loader class="mx-auto py-2" type="text" max-width="150px">
          </v-skeleton-loader>
        </v-col>
        <v-col class="text-center">
          <a-label class="mb-0">
            {{ $t("revpar") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare
              :periods="[year_period, 'stly']"
            ></a-period-compare>
          </div>
          <v-skeleton-loader class="mx-auto py-2" type="text" max-width="150px">
          </v-skeleton-loader>
        </v-col>
        <v-col class="text-center">
          <a-label class="mb-0">
            {{ $t("room_revenue") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare
              :periods="[year_period, 'stly']"
            ></a-period-compare>
          </div>
          <v-skeleton-loader class="mx-auto py-2" type="text" max-width="150px">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aLabel from "@/components/aLabel.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";

export default {
  name: "a-rms-dashboard-resume",
  components: {
    aBaseComponent,
    aLabel,
    aPeriodCompare,
    aKpi
  },
  props: {
    year_period: {
      type: String,
      default: "ytd"
    }
  },
  data: vm => ({
    subscribed_modules: ["rms_dashboard_annual_kpis"]
  }),
  methods: {},
  mounted() {},
  computed: {
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
