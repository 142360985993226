<template>
  <div class="a-currency-conversion">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon small color="secondary" @click="openDialog">
          <v-icon small>mdi-currency-usd</v-icon>
        </v-btn>
      </template>
      <span>
        {{ $t("currency_conversion_settings") }}
      </span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card class="a-currency-conversion-dialog">
        <v-card-title class="headline mb-4">
          {{ $t("currency_conversion_settings") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <template v-if="!loading">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="periods_mapped"
              :options="{ sortBy: ['start'], sortDesc: [true] }"
              :item-class="getDatatablePeriodsClass"
              class="no-elevation periods-table"
            >
              <template v-slot:item.start="{ item }">
                <v-tooltip bottom dark color="#333333">
                  <template v-slot:activator="{ on: onMenu }">
                    <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      v-model="item.menu_start_opened"
                    >
                      <template v-slot:activator="{ on: onDate }">
                        <div
                          v-on="{ ...onMenu, ...onDate }"
                          @click="
                            setPeriodToEdit([item.start_date, item.end_date])
                          "
                        >
                          <v-icon color="secondary" class="mr-2" small>
                            mdi-calendar-month-outline
                          </v-icon>
                          <b>{{ getFriendlyDate(item.start_date) }}</b>
                        </div>
                      </template>
                      <v-date-picker
                        scrollable
                        first-day-of-week="1"
                        v-model="item.start_date"
                        :allowed-dates="allowedPeriodsDates"
                        @change="setPeriod(item)"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                  <span>
                    {{ $t("change_date") }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.end="{ item }">
                <v-tooltip bottom dark color="#333333">
                  <template v-slot:activator="{ on: onMenu }">
                    <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      v-model="item.menu_end_opened"
                    >
                      <template v-slot:activator="{ on: onDate }">
                        <div
                          v-on="{ ...onMenu, ...onDate }"
                          @click="
                            setPeriodToEdit([item.start_date, item.end_date])
                          "
                        >
                          <v-icon color="secondary" class="mr-2" small>
                            mdi-calendar-month-outline
                          </v-icon>
                          <b>{{ getFriendlyDate(item.end_date) }}</b>
                        </div>
                      </template>
                      <v-date-picker
                        scrollable
                        first-day-of-week="1"
                        v-model="item.end_date"
                        :allowed-dates="allowedPeriodsDates"
                        @change="setPeriod(item)"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                  <span>
                    {{ $t("change_date") }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.currency_conversion="{ item }">
                <div class="d-flex align-center">
                  <v-text-field
                    v-model="item.currency_conversion"
                    outlined
                    rounded
                    dense
                    hide-details
                    type="number"
                    @change="setPeriod(item)"
                  ></v-text-field>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click.stop="showDeletePeriod(item)"
                  icon
                  color="secondary"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:footer>
                <div class="d-flex justify-center">
                  <v-btn
                    color="secondary"
                    class="mt-2"
                    rounded
                    outlined
                    small
                    @click="addPeriod"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $t("add_period") }}
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions class="ps-5 pb-5 mt-4 justify-center">
            <v-btn
              color="secondary"
              rounded
              depressed
              class="pl-5 pr-5"
              @click="saveChanges"
            >
              {{ $t("save_changes") }}
            </v-btn>
            <v-btn
              text
              rounded
              depressed
              class="pl-5 pr-5"
              @click="dialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <div class="pa-5">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="card"
              height="30"
              class="elevation-0 mb-3"
            >
            </v-skeleton-loader>
            <v-skeleton-loader type="actions"></v-skeleton-loader>
          </div>
        </template>
      </v-card>
    </v-dialog>

    <!-- DELETE AVAILABILITY PERIOD -->
    <v-dialog
      v-model="show_delete_period"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">
          {{ $t("are_you_sure") }}
        </v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="period_to_edit"
          v-html="
            $t('sure_delete_currency_conversion_period', {
              period: period_to_delete_dates
            })
          "
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deletePeriod()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeletePeriod"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import * as Utils from "@/utils.js";

export default {
  name: "a-currency-conversion",
  props: {
    hotel: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: true,
    dialog: false,
    show_delete_period: false,
    period_to_edit: [],
    period_to_delete_dates: null
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("start_date"), value: "start" },
        { text: this.$t("end_date"), value: "end" },
        { text: this.$t("currency_conversion"), value: "currency_conversion" },
        { text: "", value: "actions", sortable: false }
      ];
    },
    periods_mapped() {
      if (!this.periods) {
        return [];
      }
      return this.periods.map(p => {
        let dates = Utils.getFriendlyPeriod(p.start_date, p.end_date);
        return {
          dates: dates,
          start_date: p.start_date,
          end_date: p.end_date,
          currency_conversion: p.currency_conversion,
          menu_opened: false
        };
      });
    },
    ...mapState({
      periods: state => state.hotel.hotel_currency_conversion_periods
    })
  },
  methods: {
    getDatatablePeriodsClass() {
      return "a-datatable-row period-row";
    },
    openDialog() {
      this.$store
        .dispatch("getCurrencyConversionPeriodsHotel", this.hotel.id)
        .then(() => {
          this.loading = false;
        });
      this.dialog = true;
    },
    allowedPeriodsDates(date) {
      if (!this.periods || !this.period_to_edit) {
        return true;
      }
      let allowed = true;
      this.periods.forEach(p => {
        if (date >= p.start_date && date <= p.end_date) {
          allowed = false;
        }
      });
      return allowed;
    },
    setPeriodToEdit(period) {
      this.period_to_edit = period;
    },
    showDeletePeriod(item) {
      if (!item) {
        return false;
      }
      this.show_delete_period = true;
      this.period_to_edit = [item.start_date, item.end_date];
      this.period_to_delete_dates = item.dates;
    },
    hideDeletePeriod() {
      this.show_delete_period = false;
      this.period_to_edit = [];
      this.period_to_delete_dates = null;
    },
    addPeriod() {
      // Añadimo una semana despues del ultimo periodo
      const lastPeriod = this.periods.reduce(
        (max, period) =>
          Vue.moment(period.end_date).isAfter(Vue.moment(max.end_date))
            ? period
            : max,
        this.periods[0]
      );
      const newStartDate = Vue.moment(lastPeriod.end_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
      const newEndDate = Vue.moment(newStartDate)
        .add(7, "days")
        .format("YYYY-MM-DD");

      this.periods.push({
        start_date: newStartDate,
        end_date: newEndDate,
        currency_conversion: 1,
        menu_start_opened: false,
        menu_end_opened: false
      });
    },
    deletePeriod() {
      if (!this.period_to_edit) {
        return false;
      }
      this.$store.commit(
        "SET_HOTEL_CURRENCY_CONVERSION_PERIODS",
        this.periods.filter(
          p =>
            p.start_date !== this.period_to_edit[0] &&
            p.end_date !== this.period_to_edit[1]
        )
      );
      this.hideDeletePeriod();
    },
    setPeriod(item) {
      if (!item) {
        return false;
      }
      this.periods.filter(p => {
        if (p.start_date === item.start_date && p.end_date === item.end_date) {
          p.start_date = item.start_date;
          p.end_date = item.end_date;
          p.currency_conversion = item.currency_conversion;
        }
      });
      item.menu_opened = false;
    },
    saveChanges() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("saving_currency_conversion_periods"),
        color: "secondary"
      });
      this.$store
        .dispatch("setCurrencyConversionPeriodsHotel", {
          hotel_id: this.hotel.id,
          periods: this.periods
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("currency_conversion_periods_updated"),
            color: "accent"
          });
          this.dialog = false;
        });
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date, true);
    }
  }
};
</script>

<style lang="scss">
.a-currency-conversion-dialog {
  .v-data-table {
    .period-row {
      td {
        padding: 10px !important;
      }
      .v-text-field {
        max-width: 95px;
      }
    }
    .v-data-footer {
      border: none;
    }
  }
}
</style>
