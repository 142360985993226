<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="id"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "a-rms-dashboard-year-roomrevenue-monthly-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    },
    id: {
      type: String,
      default: "a-rms-dashboard-year-roomrevenue-monthly-chart"
    }
  },
  computed: {
    graph_data() {
      return {
        type: "bar",

        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 0px 0px 50px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          visible: false,
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.map(item => {
            return (
              Vue.moment(item)
                .format("MMMM")
                .substr(0, 1)
                .toUpperCase() +
              Vue.moment(item)
                .format("MMMM")
                .substr(1, 2)
            );
          })
        },
        plot: {
          aspect: "spline",
          barWidth: "98%",
          borderRadiusTopLeft: 20,
          borderRadiusTopRight: 20,
          borderRadiusBottomLeft: 20,
          borderRadiusBottomRight: 20
        },
        scaleY: {
          lineWidth: 0,
          format: "%v€",
          short: true,
          "short-unit": "K",
          guide: {
            lineStyle: "solid",
            lineColor: "transparent"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#cacaca",
            rules: [
              {
                rule: "%v == 0 ",
                visible: false
              }
            ]
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        let sui = this.hotelsdot.series_ui[serie];
        series.push({
          text: serie,
          values: this.series[serie],
          backgroundColor: sui ? sui.color : "",
          lineColor: sui ? sui.color : "",
          marker: {
            backgroundColor: sui ? sui.color : ""
          }
        });
      }
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
