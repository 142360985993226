<template>
  <div id="dashboard_rms_group_app">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row class="py-0">
          <v-col cols="6" lg="8" xl="8" class="pb-0 ">
            <v-text-field
              class="right-align"
              v-model="search"
              rounded
              outlined
              dense
              clearable
              solo
              flat
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search_hotel')"
              hide-details=""
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="2" class="pb-0"> </v-col> -->
          <v-col
            cols="6"
            lg="4"
            xl="4"
            class="pb-6 d-flex align-center justify-center"
          >
            <div
              class="rounded-pill white d-flex align-center justify-space-between py-1 px-1"
              style="height: 100%; position: relative;"
            >
              <v-btn
                small
                rounded
                :text="hotel_list_items_slide !== 0"
                :color="hotel_list_items_slide === 0 ? 'secondary' : 'black'"
                @click="hotel_list_items_slide = 0"
              >
                {{ $t("this_year") }}
              </v-btn>
              <v-btn
                small
                rounded
                :text="hotel_list_items_slide !== 1"
                :color="hotel_list_items_slide === 1 ? 'secondary' : 'black'"
                @click="hotel_list_items_slide = 1"
                class="mx-2"
              >
                {{ $t("this_month") }}
              </v-btn>
              <v-btn
                small
                rounded
                :text="hotel_list_items_slide !== 2"
                :color="hotel_list_items_slide === 2 ? 'secondary' : 'black'"
                @click="hotel_list_items_slide = 2"
              >
                {{ $t("next_month") }}
              </v-btn>
            </div>
            <a-rms-vs-selector-single
              :value_vs="dashboard_group_vs_data"
              :current_vs="dashboard_group_vs_data"
              @updateVs="updateVs"
              dark
              class="d-block ml-2"
              block
              hide_pickup
            ></a-rms-vs-selector-single>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-responsive
              id="dashboard-rms-group-app"
              class="overflow-y-auto"
              max-height="calc(100vh - 180px)"
            >
              <v-responsive :height="height">
                <v-lazy
                  v-for="group_hotel in group_hotels"
                  :key="group_hotel.id"
                  v-model="isActive[group_hotel.id]"
                  :options="{
                    threshold: 0.5
                  }"
                  :min-height="cardHeight"
                  transition="fade-transition"
                  class="col col-12"
                >
                  <a-hotel-group-list-item
                    :hotel="group_hotel"
                  ></a-hotel-group-list-item>
                </v-lazy>
                <v-lazy
                  v-for="h in filtered_hotels"
                  :key="h.id"
                  v-model="isActive[h.id]"
                  :options="{
                    threshold: 0.5
                  }"
                  :min-height="cardHeight"
                  transition="fade-transition"
                  class="col col-12 px-10"
                >
                  <a-hotel-list-item :hotel="h"></a-hotel-list-item>
                </v-lazy>
              </v-responsive>
            </v-responsive>
          </v-col>
        </v-row>
      </template>
      <template v-slot:loading>
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                  </v-col>
                  <v-col cols="8">
                    <v-skeleton-loader type="image"></v-skeleton-loader>
                  </v-col>
                  <v-col cols="4">
                    <v-skeleton-loader
                      type="list-item-avatar"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-3"
                    ></v-skeleton-loader>
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-3"
                    ></v-skeleton-loader>
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                  </v-col>
                  <v-col cols="8">
                    <v-skeleton-loader type="image"></v-skeleton-loader>
                  </v-col>
                  <v-col cols="4">
                    <v-skeleton-loader
                      type="list-item-avatar"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-3"
                    ></v-skeleton-loader>
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-3"
                    ></v-skeleton-loader>
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-4"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-2"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-2"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12">
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-4"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-2"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="text"
                      class="mt-2"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-for="i in 3" :key="i">
            <a-card no_title>
              <v-row>
                <v-col cols="6">
                  <v-skeleton-loader type="heading"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    type="image"
                    height="120"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    type="image"
                    height="120"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </a-card>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aCard from "@/components/aCard.vue";
import aHotelListItem from "@/components/dashboard/aHotelListItem.vue";
import aHotelGroupListItem from "@/components/dashboard/aHotelGroupListItem.vue";
import aRmsVsSelectorSingle from "@/components/rms/aRmsVsSelectorSingle";

export default {
  name: "dashboard-rms-group-app",
  components: {
    aBaseComponent,
    aCard,
    aHotelListItem,
    aHotelGroupListItem,
    aRmsVsSelectorSingle
  },
  props: {
    period: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    tab: 0,
    subscribed_modules: [],
    visible_elements: [],
    search: "",
    isActive: {},
    cardHeight: 162
  }),
  methods: {
    updateVs(value) {
      this.dashboard_group_vs_data = value;
    }
  },
  watch: {
    hotels() {
      for (let hotel in this.hotels) {
        this.$set(this.isActive, hotel.id, false);
      }
    }
  },
  computed: {
    hotel_list_items_slide: {
      get() {
        return this.$store.state.rms.hotel_list_items_slide;
      },
      set(value) {
        this.$store.dispatch("setHotelListItemSlide", value);
      }
    },
    dashboard_group_vs_data: {
      get() {
        return this.$store.state.rms.dashboard_group_vs_data;
      },
      set(value) {
        this.$store.dispatch("setDashboardGroupVsData", value);
      }
    },
    hotel_last_level_childs() {
      return this.current_hotel && this.current_hotel.settings
        ? this.current_hotel.settings.last_level_childs
        : [];
    },
    filtered_hotels() {
      let hotels = this.hotels && this.hotels.length > 0 ? this.hotels : [];
      if (this.search && this.search.length > 0) {
        hotels = hotels.filter(c =>
          c.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return hotels
        .filter(h => h.children_number === 0)
        .filter(h => this.hotel_last_level_childs.includes(h.id))
        .map(hotel => {
          hotel.visible = false;
          hotel.loaded = false;
          return hotel;
        });
    },
    group_hotels() {
      let hotels = this.hotels && this.hotels.length > 0 ? this.hotels : [];
      if (this.search && this.search.length > 0) {
        hotels = hotels.filter(c =>
          c.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return hotels
        .filter(h => h.id === this.current_hotel.id)
        .map(hotel => {
          hotel.visible = false;
          hotel.loaded = false;
          return hotel;
        });
    },
    num_hotels() {
      return this.hotels
        ? this.filtered_hotels.length + this.group_hotels.length
        : 0;
    },
    height() {
      return this.num_hotels > 0 ? this.num_hotels * this.cardHeight : "100vh";
    },
    ...mapState({
      hotels: state => state.hotel.hotels
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
