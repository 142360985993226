<template>
  <!-- RATE DATE TO COMPARE -->
  <div id="dashboard_rms_app">
    <a-card :title="year_title">
      <template v-slot:default>
        <a-rms-dashboard-resume
          ref="a-rms-dashboard-resume"
          :year_period="year_period"
        ></a-rms-dashboard-resume>
        <v-row>
          <v-col cols="12" class="pb-0 pt-2" style="position:relative;">
            <div
              style="position: absolute; top:21px; height: 1px; background: #f5f5f5; width: calc(100% - 35px);"
            ></div>
            <div class="ml-4 pl-12 py-2 pr-4" style="">
              <div
                :style="
                  'border-right: 1px dashed #cacaca; position:relative;width: ' +
                    year_width +
                    '%;'
                "
              >
                <div
                  class="period_selected"
                  :class="year_view_full ? 'active' : ''"
                >
                  <div class="arrowTop"></div>
                </div>
                <div
                  class=""
                  :style="'position:relative;width: ' + year_ytd_width + '%;'"
                >
                  <div
                    class="period_selected"
                    :class="!year_view_full ? 'active' : ''"
                  >
                    <div class="arrowTop"></div>
                  </div>
                </div>
                <div
                  class=""
                  :style="
                    'background-color:rgba(100,100,100,0.05);height: 100px;position:relative;width: ' +
                      year_ytd_width +
                      '%;'
                  "
                ></div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="py-0" style="margin-top: -125px;">
            <v-card color="transparent" flat>
              <v-card-text class="pb-0">
                <a-base-component :subscribed_modules="subscribed_modules">
                  <template v-slot:default>
                    <a-dashboard-year-sales-roomrevenue-monthly-chart
                      :series="year_sales_monthly_graph.kpis.room_revenue"
                      :labels="year_sales_monthly_graph.labels"
                      height="100"
                    ></a-dashboard-year-sales-roomrevenue-monthly-chart>
                  </template>
                </a-base-component>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pb-0 pt-2">
            <div
              class="ml-4 pl-12 pr-4 text-center d-flex justify-center w-100"
            >
              <div
                v-for="m in months"
                :key="m.month + '_' + m.year"
                class="px-1"
                style="width: 100%;"
              >
                <v-btn
                  block
                  text
                  x-small
                  rounded
                  :color="m.color"
                  @click="goToStrategy(m)"
                >
                  {{ m.name }}</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:subtitle> </template>
      <template v-slot:pretitle>
        <v-btn
          icon
          outlined
          x-small
          rounded
          color="grey darken-2"
          @click="year--"
          :disabled="year < current_year"
          class="mr-1"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          :disabled="year > current_year"
          icon
          outlined
          x-small
          rounded
          color="grey darken-2"
          @click="year++"
          class="mr-2"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:options>
        <v-tabs
          v-model="year_tab"
          background-color="transparent"
          small
          right
          class="justify-end text-right w-50"
          style="max-width:50%;"
        >
          <v-tab
            v-for="item in ['ytd', 'full']"
            :key="item"
            :value="item"
            :disabled="current_year !== year && item === 'ytd'"
          >
            {{ $t("period_" + item) }}
          </v-tab>
          <v-tabs-slider></v-tabs-slider>
        </v-tabs>
      </template>
    </a-card>
    <a-rms-dashboard-months
      ref="a-rms-dashboard-months"
      :pickup_months="pickup_months"
    ></a-rms-dashboard-months>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aCard from "@/components/aCard.vue";
import aRmsDashboardResume from "@/components/rms/aRmsDashboardResume.vue";
import aRmsDashboardMonths from "@/components/rms/aRmsDashboardMonths.vue";
import aDashboardYearSalesRoomrevenueMonthlyChart from "@/components/chart/aDashboardYearSalesRoomrevenueMonthlyChart.vue";

export default {
  name: "dashboard-rms-app",
  components: {
    aBaseComponent,
    aDashboardYearSalesRoomrevenueMonthlyChart,
    aCard,
    aRmsDashboardResume,
    aRmsDashboardMonths
  },
  data: vm => ({
    subscribed_modules: ["rms_dashboard_year_sales_monthly_graph"],
    current_year: Vue.moment().year(),
    year: Vue.moment().year(),
    year_tab: 0
  }),
  watch: {
    year() {
      this.$store.dispatch("setRmsDashboardYear", this.year);
      this.year_tab = 1;
    },
    year_view_full(value) {
      this.$store.dispatch("setRmsDashboardYearFullView", value);
    },
    first_day_of_months(value) {
      this.$store.dispatch("setDashboardFirstDay", value);
    },
    last_day_of_months(value) {
      this.$store.dispatch("setDashboardLastDay", value);
    }
  },
  methods: {
    getMonthName(month, year) {
      if (!year) return "";
      return Vue.moment()
        .month(month - 1)
        .year(year)
        .format("MMMM YY");
    },
    getMonthStyle(month, year) {
      if (!year) return "";

      return Vue.moment()
        .month(month - 1)
        .year(year)
        .isBefore(Vue.moment())
        ? "primary"
        : "secondary";
    },
    goToStrategy(month) {
      this.$store.dispatch("setStrategyFrom", month.date_from);
      this.$store.dispatch("setStrategyTo", month.date_to);
      if (
        this.current_user &&
        this.current_hotel &&
        this.current_user.permissions.rms_strategy_axel &&
        this.current_hotel.permissions.rms_strategy_axel
      ) {
        this.$router.push({
          name: "rms_strategy_axel"
        });
      } else if (
        this.current_user &&
        this.current_hotel &&
        this.current_user.permissions.rms_strategy &&
        this.current_hotel.permissions.rms_strategy
      ) {
        this.$router.push({
          name: "rms_strategy"
        });
      }
    },
    ...mapActions([])
  },
  created() {
    this.kpi_graph = "room_revenue";
    this.year = Vue.moment().year();
    this.$store.dispatch("setRmsDashboardYear", this.year);
    this.dashboard_first_day = this.first_day_of_months;
    this.dashboard_last_day = this.last_day_of_months;
  },
  computed: {
    year_period() {
      return this.year_view_full ? "otb" : "ytd";
    },
    year_view_full() {
      return this.year_tab ? true : false;
    },
    year_title() {
      let titles = [];
      titles.push(this.$t("year_evolution"));
      titles.push("<span class='secondary--text'>" + this.year + "</span>");
      return titles.join(" ");
    },
    year_width() {
      return (
        (this.months.filter(m => m.year === this.year).length * 100) /
        this.months.length
      );
    },
    year_ytd_width() {
      return this.current_year === this.year
        ? (Vue.moment().dayOfYear() * 100) / 365
        : this.current_year < this.year
        ? 0
        : 100;
    },
    first_day_of_months() {
      // get the date from the first_day from the this.months array of months
      let first_month = this.months[0];
      let first_day = Vue.moment()
        .year(first_month.year)
        .month(first_month.month - 1)
        .date(1)
        .format("YYYY-MM-DD");
      return first_day;
    },
    last_day_of_months() {
      // get the date from the last_day from the this.months array of months
      let last_month = this.months[this.months.length - 1];
      let last_day = Vue.moment()
        .year(last_month.year)
        .month(last_month.month - 1)
        .endOf("month")
        .format("YYYY-MM-DD");
      return last_day;
    },
    months() {
      let months = [];
      let year = this.year;
      for (let i = 1; i <= 12; i++) {
        months.push({
          month: i,
          // name: this.getMonthName(i, year),
          year: year
        });
      }
      if (Vue.moment().month() >= 10 && year === this.current_year) {
        months.push({
          month: 1,
          // name: this.getMonthName(1, year + 1),
          year: year + 1
        });
      }
      if (Vue.moment().month() == 11 && year === this.current_year) {
        months.push({
          month: 2,
          // name: this.getMonthName(2, year + 1),
          year: year + 1
        });
      }

      return months.map(m => {
        return {
          ...m,
          name: this.getMonthName(m.month, m.year),
          color: this.getMonthStyle(m.month, m.year),
          date_from: Vue.moment()
            .year(m.year)
            .month(m.month - 1)

            .startOf("month")
            .format("YYYY-MM-DD"),
          date_to: Vue.moment()
            .year(m.year)
            .month(m.month - 1)
            .endOf("month")
            .format("YYYY-MM-DD")
        };
      });
    },
    pickup_months() {
      let months = [];
      let init_month = this.dashboard_pickup_months_page * 3;
      let end_month = (this.dashboard_pickup_months_page + 1) * 3;
      for (let i = init_month; i < end_month; i++) {
        let m = Vue.moment()
          .startOf("month")
          .add(i, "months");
        months.push({
          month: m.month() + 1,
          year: m.year()
        });
      }

      return months.map(m => {
        return {
          ...m,
          name: this.getMonthName(m.month, m.year),
          color: this.getMonthStyle(m.month, m.year),
          date_from: Vue.moment()
            .year(m.year)
            .month(m.month - 1)

            .startOf("month")
            .format("YYYY-MM-DD"),
          date_to: Vue.moment()
            .year(m.year)
            .month(m.month - 1)
            .endOf("month")
            .format("YYYY-MM-DD")
        };
      });
    },
    dashboard_pickup_months_page: {
      get() {
        return this.$store.state.rms.dashboard_pickup_months_page;
      },
      set(value) {
        this.$store.dispatch("setDashboardPickupMonthsPage", value);
      }
    },
    kpi_graph: {
      get() {
        return this.$store.state.rms.year_sales_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiGraph", value);
      }
    },
    dashboard_first_day: {
      get() {
        return this.$store.state.rms.dashboard_first_day;
      },
      set(value) {
        this.$store.dispatch("setDashboardFirstDay", value);
      }
    },
    dashboard_last_day: {
      get() {
        return this.$store.state.rms.dashboard_last_day;
      },
      set(value) {
        this.$store.dispatch("setDashboardLastDay", value);
      }
    },
    ...mapState({
      year_sales_monthly_graph: state => state.rms.year_sales_monthly_graph,
      dashboard_year_sales_monthly_graph_status: state =>
        state.rms.dashboard_year_sales_monthly_graph_status,
      rms: state => state.rms
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("setReloadSubscribedModules");
    });
  }
};
</script>
<style scoped lang="scss">
.period_selected {
  width: 100%;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  height: 6px;
  background: #cacaca;
  margin-bottom: 3px;
  display: none;
  &.active {
    display: block;
    background: #999999;
  }
  .arrowTop {
    position: absolute;
    margin: 20px;
    width: 38px;
    height: 0px;
    background: #999999;
    border: 0px;
    top: -10px;
    left: calc(50% - 19px);
  }

  .arrowTop:before,
  .arrowTop:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -1px;
    content: " ";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
  }
  .arrowTop:after {
    border-bottom: 6px solid #999999;
    top: -15px;
    z-index: 2;
  }

  .arrowTop:before {
    border-bottom: 6px solid #999999;
    top: -16px;
    z-index: 1;
  }
}
</style>
